import React, { useState, useEffect } from 'react';
import { Table, Pagination } from 'react-bootstrap';
import sgmData from './API/Sgm_dataApi'; // Import your JSON data
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons';

const Sgm = () => {
    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPage] = useState(10);
    const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });
    const [searchTerm, setSearchTerm] = useState('');
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);

    useEffect(() => {
        // You don't need to fetch data as it's already imported
        // For fetching data from an API, you would use the fetch function as before
    }, []);

    const sortedData = () => {
        let sortedData = [...sgmData];
        if (sortConfig.key !== null) {
            sortedData.sort((a, b) => {
                if (a[sortConfig.key] < b[sortConfig.key]) {
                    return sortConfig.direction === 'asc' ? -1 : 1;
                }
                if (a[sortConfig.key] > b[sortConfig.key]) {
                    return sortConfig.direction === 'asc' ? 1 : -1;
                }
                return 0;
            });
        }
        return sortedData;
    };

    const requestSort = (key) => {
        let direction = 'asc';
        if (sortConfig.key === key && sortConfig.direction === 'asc') {
            direction = 'desc';
        }
        setSortConfig({ key, direction });
    };

    const handleSearch = (event) => {
        setSearchTerm(event.target.value);
        setCurrentPage(1); // Reset pagination when searching
    };

    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    const currentPosts = sortedData().slice(indexOfFirstPost, indexOfLastPost);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    return (
        <>
        <div className="container-fluid main-sgm-container d-flex flex-column justify-content-center align-items-center">
            <h1 className="main-heading text-center fw-bold mt-5">Special General Body Meeting</h1>
            <input
                type="text"
                placeholder="Search..."
                onChange={handleSearch}
                className="form-control mb-3 w-20"
            />
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th onClick={() => requestSort('id')}>ID</th>
                        <th onClick={() => requestSort('date')}>Date</th>
                        
                        <th>SGM</th>
                    </tr>
                </thead>
                <tbody className='tablesgm'>
                    {currentPosts
                        .filter((item) =>
                            Object.values(item).some((value) =>
                                value.toString().toLowerCase().includes(searchTerm.toLowerCase())
                            )
                        )
                        .map((item, index) => (
                            <tr key={index}>
                                <td className='sgmdata'>{item.id}</td>
                                <td className='sgmdata'>{item.date}</td>
                              
                                <td>
                                    <a href={item.pdf} target="_blank" rel="noopener noreferrer">
                                        <FontAwesomeIcon className='icon' icon={faFilePdf} />
                                    </a>
                                </td>
                            </tr>
                        ))}
                </tbody>
            </Table>
            <Pagination>
                {[...Array(Math.ceil(sgmData.length / postsPerPage)).keys()].map((number) => (
                    <Pagination.Item key={number} active={number + 1 === currentPage} onClick={() => paginate(number + 1)}>
                        {number + 1}
                    </Pagination.Item>
                ))}
            </Pagination>

        </div>
</>
    );
};

export default Sgm;
